<script setup lang="ts">
    import Invoice from '@/app/invoicing/models/Invoice';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import ModelFileSchema from '@/app/common/schemas/ModelFileSchema';
    import EndpointFactory from '@/app/factories/EndpointFactory';
    import { makeId } from '~/utils/string-helpers';

    const props = defineProps({
  invoice: null,
  withUpload: { type: Boolean }
});
    const uploadFileInput = ref(null);
    const inputFileId = ref(makeId());
    const uploading = ref(false);

    const onFileUpload = async (event: Event) => {
        event.preventDefault();
        await uploadFiles(event.target.files);
    };

    const uploadFiles = async (files: Array<File>) => {
        uploading.value = true;

        let promises = [];
        for (let i = 0; i < files.length; i++) {
            promises.push(uploadFile(files[i]));
        }

        await Promise.all(promises);
        uploadFileInput.value!.value = '';
        uploading.value = false;
    };

    const uploadFile = async (file: File) => {
        try {
            let endpoint = EndpointFactory.make(ModelType.MODEL_FILES);
            let schema = new ModelFileSchema({ attributes: { title: file.name } });
            schema.addToOneRelationship('model', ModelType.INVOICES, props.invoice.getId());

            let response = await endpoint.upload(schema, file);
            useToasteoSuccess('toasts.upload_success');
            return response.data;
        } catch (error) {
            console.log(error);
            useToasteoError('toasts.upload_failed');
            return null;
        }
    };
</script>

<template>
    <div>
        <div
            class="flex flex-col gap-1 mb-4"
            v-if="invoice.modelFiles?.isNotEmpty()"
        >
            <div
                class="p-2 text-xs text-gray-600 transition-all border border-gray-100 rounded cursor-pointer hover:text-primary hover:bg-neutral-50"
                v-for="modelFile in invoice.modelFiles.get()"
                :key="modelFile.getInternalId()"
                @click.prevent="modelFile.preview()"
                @contextmenu="modelFile.openContextMenu($event)"
            >
                <span>{{ modelFile.title }}</span>
            </div>
        </div>
        <label
            :for="inputFileId"
            class="relative flex items-center gap-2 text-sm transition-all cursor-pointer text-primary hover:text-primary-light hover:underline"
        >
            <Loader v-if="uploading" />
            <i class="fa-regular fa-link" />
            {{ $tc('misc.files_linked_count', invoice?.modelFiles?.count() ?? 0, { count: invoice?.modelFiles?.count() }) }}
            <input
                ref="uploadFileInput"
                multiple
                :id="inputFileId"
                class="hidden"
                :name="inputFileId"
                type="file"
                @change="onFileUpload"
            />
        </label>
    </div>
</template>
