export default (keys: string | string[], callback: () => void, modifiers: string[] = ['metaKey']) => {
    if (!Array.isArray(keys)) {
        keys = [keys];
    }

    const listenerCallback = (e: KeyboardEvent) => {
        /**
         * Check that all modifiers are pressed first.
         */
        if (!modifiers.every((modifier) => e[modifier])) {
            return;
        }

        if (keys.includes(e.key)) {
            e.preventDefault();
            callback();
        }
    };

    onBeforeMount(() => {
        // console.log('Global shortcut added', {keys, modifiers});
        window.addEventListener('keydown', listenerCallback);
    });

    onBeforeUnmount(() => {
        // console.log('Global shortcut removed', {keys, modifiers});
        window.removeEventListener('keydown', listenerCallback);
    });
};
