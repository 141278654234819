<script setup lang="ts">
interface Props {
        filterByContact?: _VTI_TYPE_Contact | null;
        withCreateButton?: boolean;
        multiple?: boolean;
        initialValue?: _VTI_TYPE_Lead | null;
        closeOnSelect?: boolean;
        submenuPosition?: 'left-0' | 'right-0';
    }
    
    import { EndpointResponse } from '~~/app/base/endpoints/types'
    import { ModelType } from '~~/app/base/schemas/BaseSchema'
    import EndpointFactory from '~~/app/factories/EndpointFactory'
    import LeadCollection from '~~/app/crm/collections/LeadCollection'
    import LeadEndpoint from '~~/app/crm/endpoints/LeadEndpoint'
    
    const emit = defineEmits(['selected', 'hidden']);
    
    const props = defineProps({
  filterByContact: { default: null },
  withCreateButton: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  initialValue: { default: null },
  closeOnSelect: { type: Boolean, default: true },
  submenuPosition: { default: 'left-0' }
});
    const loading = ref(true);
    const submenu = ref(null);
    const leads = ref<LeadCollection | null>(null);
    const selected = ref<Lead | Array<Lead> | null>(props.initialValue);
    const query = ref<string>('');
    const isSelected = (lead: Lead) => {
        return selected.value && lead && selected.value.getId() == lead.getId();
    };
    const select = (lead: Lead | null) => {
        selected.value = lead;
        emit('selected', lead);
        if (props.closeOnSelect) {
            submenu.value?.hide();
        }
    };
    const setValue = (lead: Lead) => {
        selected.value = lead;
    };
    const getLeads = async (force = false) => {
        if (leads.value && !force) {
            loading.value = false;
            return;
        }
        loading.value = true;
        const endpoint = EndpointFactory.make(ModelType.LEADS) as LeadEndpoint;
        const params: { [k: string]: any } = {
            include: 'contact',
            filter: {
                search: query.value,
            },
            page: {
                size: 5,
            },
        };
        // if (props.filterByContact) {
        //   params.filter.contact = {
        //     id: [props.filterByContact.getId()],
        //   };
        // }
        const response = await endpoint.index(params);
        if (response.error) {
            console.error('Could not retrieve leads.');
            submenu.value?.hide();
            return;
        }
        leads.value = response.data;
        loading.value = false;
    };
    const show = () => {
        if (!submenu.value?.isDisplayed) {
            submenu.value?.show();
        }
    };
    useListen('crm:lead:created', (lead) => {
        if (props.withCreateButton) {
            select(lead);
        }
    });
    defineExpose({
        show,
        setValue,
    });
</script>

<template>
    <Submenu
        ref="submenu"
        :position="submenuPosition"
        :container="$theme('submenu.container.xl')"
        max-height="max-h-[400px]"
        @shown="getLeads"
        @hidden="emit('hidden', selected)"
    >
        <template #default="slotProps">
            <div
                class="px-3 py-2 transition-all border border-gray-200 rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-primary group"
                @click.prevent="slotProps.toggle()"
                @contextmenu="($event) => (selected ? selected.openContextMenu($event) : null)"
            >
                <div class="flex items-center space-x-2">
                    <div>
                        <i class="transition-all text-neutral-600 group-hover:text-primary fa-regular fa-bullseye-arrow"></i>
                    </div>
                    <div class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                        <span
                            class="text-sm transition-all text-neutral-500 group-hover:text-primary"
                            v-text="selected ? selected.title : $t('selects.leads.placeholder')"
                        ></span>
                    </div>
                    <template v-if="withCreateButton">
                        <a
                            href="#"
                            :class="$theme('button.style.gray_xs', 'hover:bg-neutral-300')"
                            @click.stop.prevent="
                                useEvent('crm:lead:form-modal:open', {
                                    fields: { contact: filterByContact },
                                })
                            "
                        >
                            <span class="inline">
                                <i class="fa-regular fa-plus" />
                            </span>
                        </a>
                    </template>
                </div>
            </div>
        </template>

        <template #submenu>
            <div class="w-full min-w-[260px]">
                <div class="mb-2">
                    <p>
                        {{ $t('selects.leads.title') }}
                    </p>
                    <p
                        v-if="filterByContact"
                        class="block text-xs text-neutral-500 max-w-[300px] whitespace-normal"
                    >
                        {{
                            $t('selects.lead.filtered_by_contact', {
                                name: filterByContact.displayName,
                            })
                        }}
                    </p>
                </div>
                <div class="relative mb-2">
                    <input
                        v-model="query"
                        type="search"
                        class="w-full py-2 pl-4 pr-8 text-sm rounded-lg bg-neutral-100"
                        :placeholder="$t('selects.leads.search_placeholder')"
                        @keyup.enter.prevent="getLeads(true)"
                    />
                    <div class="absolute top-0 bottom-0 right-0 flex items-center pr-2">
                        <i
                            class="text-sm transition-all cursor-pointer text-neutral-600 fa-regular fa-search hover:text-black"
                            @click.prevent.stop="getLeads(true)"
                        ></i>
                    </div>
                </div>
                <div class="relative">
                    <div
                        v-if="loading"
                        class="py-6"
                    >
                        <Loader></Loader>
                    </div>
                    <template v-else>
                        <SubmenuEmptyContent v-if="leads?.isEmpty()" />
                        <div
                            v-else
                            class="flex flex-col"
                        >
                            <div
                                v-for="lead in leads?.get()"
                                :key="lead.getInternalId()"
                                :class="[$theme('contextmenu.link', 'cursor-pointer'), { 'bg-neutral-100': isSelected(lead) }]"
                                @click.prevent="select(lead)"
                            >
                                <div>
                                    <span class="block text-sm">{{ lead.title }}</span>
                                </div>
                                <div
                                    v-if="isSelected(lead)"
                                    class="absolute top-0 bottom-0 right-0 flex items-center pr-3"
                                >
                                    <a
                                        href="#"
                                        @click.prevent.stop="select(null)"
                                    >
                                        <i class="text-neutral-400 fa-regular fa-xmark hover:text-neutral-600"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <template v-if="withCreateButton">
                            <a
                                href="#"
                                :class="$theme('contextmenu.link')"
                                @click.stop.prevent="
                                    useEvent('crm:lead:form-modal:open', {
                                        fields: { contact: filterByContact },
                                    })
                                "
                            >
                                <i
                                    class="fa-plus"
                                    :class="$theme('contextmenu.icon')"
                                ></i>
                                <span>{{ $t('quick_actions.add_lead') }}</span>
                            </a>
                        </template>
                    </template>
                </div>
            </div>
        </template>
    </Submenu>
</template>
