<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface Props {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        withCreateButton?: boolean;
    }
    import WasteCenter from '../../../app/common/models/WasteCenter'
    import { FormInputData } from '~~/composables/useFormInput'
    
    const emit = defineEmits(['selected']);
    const select = ref(null);
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean },
  withCreateButton: { type: Boolean, default: false }
});
    const inputData: FormInputData = useFormInput(props);
    const displayCreateButton = computed(() => props.withCreateButton);
    useListen('common:waste-center:updated', (wasteCenter) => {
        if (inputData.model.value && wasteCenter.is(inputData.model.value)) {
            inputData.model.value = wasteCenter;
        }
    });
    useListen('worksite:waste-center:deleted', (uuid) => {
        if (inputData.model?.value?.getId() === uuid) {
            inputData.model.value = null;
        }
    });
    const selectWasteCenter = (wasteCenter: WasteCenter | null) => {
        inputData.model.value = wasteCenter;
        emit('selected', wasteCenter);
    };
</script>

<template>
    <FormElement
        :input-data="inputData"
        @label-clicked="select?.show()"
    >
        <SelectWasteCenter
            ref="select"
            :initial-value="inputData.model.value"
            :with-create-button="displayCreateButton"
            @selected="selectWasteCenter"
        >
        </SelectWasteCenter>
    </FormElement>
</template>
