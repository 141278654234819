import { useEvent } from '~/composables/useEventBus';

export const useModalsStore = defineStore('ModalsStore', () => {
    const modals = ref<string[]>([]);

    const displayOverlay = ref<boolean>(false);
    const shouldDisplayOverlay = computed<boolean>(() => modals.value.length > 0);

    const getLatestModal = () => {
        return modals.value[modals.value.length - 1];
    };

    const getModalIndex = (name: string) => {
        return modals.value.findLastIndex((modal: any) => modal === name);
    };

    const getModalZIndex = (name: string) => {
        return 900 + getModalIndex(name) * 2;
    };

    const getClickableOverlayZIndex = () => {
        return getModalZIndex(getLatestModal()) - 1;
    };

    const closeLatestModal = async () => {
        const name = getLatestModal();

        /**
         * The TermsOfService acceptation modal is not closable
         * unless the user agrees to them first.
         */
        if (name == 'common:terms-of-service:accept-modal') {
            return;
        }

        useEvent(name + ':close');

        await nextTick();
        if (getModalIndex(name) > -1) {
            useEvent('modal:close', name);
        }
    };

    useListen('modal:open', (name: string) => {
        if (getModalIndex(name) > -1) {
            modals.value.splice(getModalIndex(name), 1);
        }

        modals.value.push(name);
        if (shouldDisplayOverlay.value) {
            displayOverlay.value = true;
        }
    });

    useListen('modal:close', (name: string) => {
        const index = getModalIndex(name);
        if (index > -1) {
            modals.value.splice(index, 1);
        }
        if (!shouldDisplayOverlay.value) {
            displayOverlay.value = false;
        }
    });

    useHead({
        bodyAttrs: {
            class: computed(() => {
                return displayOverlay.value ? '' : '';
            }),
        },
    });

    return {
        modals,
        displayOverlay,
        getModalIndex,
        getModalZIndex,
        getClickableOverlayZIndex,
        closeLatestModal,
    };
});
