import { BaseSchema, ModelType, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type ComposedWorkLineRelationships = {
    parent: ToOneRelationship<ModelType.PRODUCTS>;
    productUnit: ToOneRelationship<ModelType.PRODUCT_UNITS>;
};

type ComposedWorkLineAttributes = {
    title: string;
    price: number;
    purchasePrice: number;
    quantity: number;
};

type ComposedWorkLineMeta = {};

export default class ComposedWorkLineSchema extends BaseSchema<ComposedWorkLineAttributes, ComposedWorkLineMeta, ComposedWorkLineRelationships> {
    protected type: ModelType = ModelType.COMPOSED_WORK_LINES;
}
