<script setup lang="ts">
interface Props {
        filterByContact?: _VTI_TYPE_Contact | null;
        withCreateButton?: boolean;
        multiple?: boolean;
        initialValue?: _VTI_TYPE_Lead | null;
        closeOnSelect?: boolean;
        submenuPosition?: 'left-0' | 'right-0';
    }
    import WasteCenterCollection from '../../../app/common/collections/WasteCenterCollection'
    import WasteCenter from '../../../app/common/models/WasteCenter'
    
    import { ModelType } from '~~/app/base/schemas/BaseSchema'
    import EndpointFactory from '~~/app/factories/EndpointFactory'
    
    const emit = defineEmits(['selected', 'hidden']);
    
    const props = defineProps({
  filterByContact: { default: null },
  withCreateButton: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  initialValue: { default: null },
  closeOnSelect: { type: Boolean, default: true },
  submenuPosition: { default: 'left-0' }
});
    const loading = ref(true);
    const submenu = ref(null);
    const wasteCenters = ref<WasteCenterCollection | null>(null);
    const selected = ref<WasteCenter | Array<WasteCenter> | null>(props.initialValue);
    const query = ref<string>('');
    const isSelected = (wasteCenter: WasteCenter) => {
        return selected.value && wasteCenter && selected.value.getId() === wasteCenter.getId();
    };
    const select = (wasteCenter: WasteCenter | null) => {
        selected.value = wasteCenter;
        emit('selected', wasteCenter);
        if (props.closeOnSelect) {
            submenu.value?.hide();
        }
    };
    const setValue = (wasteCenter: WasteCenter) => {
        selected.value = wasteCenter;
    };
    const getWasteCenters = async (force = false) => {
        if (wasteCenters.value && !force) {
            loading.value = false;
            return;
        }
        loading.value = true;
        const endpoint = EndpointFactory.make(ModelType.WASTE_CENTER);
        // const params: { [k: string]: any } = {
        //   page: {
        //     size: 5,
        //   },
        // };
        const response = await endpoint.index();
        if (response.error) {
            console.error('Could not retrieve waste centers.');
            submenu.value?.hide();
            return;
        }
        wasteCenters.value = response.data;
        loading.value = false;
    };
    const show = () => {
        if (!submenu.value?.isDisplayed) {
            submenu.value?.show();
        }
    };
    useListen('common:waste-center:created', (wasteCenter) => {
        if (props.withCreateButton) {
            select(wasteCenter);
        }
    });
    defineExpose({
        show,
        setValue,
    });
</script>

<template>
    <Submenu
        ref="submenu"
        :position="submenuPosition"
        :container="$theme('submenu.container.xl')"
        max-height="max-h-[400px]"
        @shown="getWasteCenters"
        @hidden="emit('hidden', selected)"
    >
        <template #default="slotProps">
            <div
                class="px-3 py-2 transition-all border border-gray-200 rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-primary group"
                @click.prevent="slotProps.toggle()"
                @contextmenu="($event) => (selected ? selected.openContextMenu($event) : null)"
            >
                <div class="flex items-center space-x-2">
                    <div>
                        <i class="transition-all text-neutral-600 group-hover:text-primary fa-regular fa-bullseye-arrow"></i>
                    </div>
                    <div class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                        <span
                            class="text-sm transition-all text-neutral-500 group-hover:text-primary"
                            v-text="selected ? selected?.title : $t('selects.wasteCenters.placeholder')"
                        ></span>
                    </div>
                    <template v-if="withCreateButton">
                        <a
                            href="#"
                            :class="$theme('button.style.gray_xs', 'hover:bg-neutral-300')"
                            @click.stop.prevent="useEvent('common:waste-center:form-modal:open')"
                        >
                            <span class="inline">
                                <i class="fa-regular fa-plus" />
                            </span>
                        </a>
                    </template>
                </div>
            </div>
        </template>

        <template #submenu>
            <div class="w-full min-w-[260px]">
                <div class="relative mb-2">
                    <input
                        v-model="query"
                        type="search"
                        class="w-full py-2 pl-4 pr-8 text-sm rounded-lg bg-neutral-100"
                        :placeholder="$t('selects.wasteCenters.search_placeholder')"
                        @keyup.enter.prevent="getWasteCenters(true)"
                    />
                    <div class="absolute top-0 bottom-0 right-0 flex items-center pr-2">
                        <i
                            class="text-sm transition-all cursor-pointer text-neutral-600 fa-regular fa-search hover:text-black"
                            @click.prevent.stop="getWasteCenters(true)"
                        ></i>
                    </div>
                </div>
                <div class="relative">
                    <div
                        v-if="loading"
                        class="py-6"
                    >
                        <Loader></Loader>
                    </div>
                    <template v-else>
                        <SubmenuEmptyContent v-if="wasteCenters?.isEmpty()" />
                        <div
                            v-else
                            class="flex flex-col"
                        >
                            <div
                                v-for="center in wasteCenters?.get()"
                                :key="center.getInternalId()"
                                :class="[$theme('contextmenu.link', 'cursor-pointer'), { 'bg-neutral-100': isSelected(center) }]"
                                @click.prevent="select(center)"
                            >
                                <div>
                                    <span class="block text-sm">{{ center.title }}</span>
                                </div>
                                <div
                                    v-if="isSelected(center)"
                                    class="absolute top-0 bottom-0 right-0 flex items-center pr-3"
                                >
                                    <a
                                        href="#"
                                        @click.prevent.stop="select(null)"
                                    >
                                        <i class="text-neutral-400 fa-regular fa-xmark hover:text-neutral-600"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <template v-if="withCreateButton">
                            <a
                                href="#"
                                :class="$theme('contextmenu.link')"
                                @click.stop.prevent="useEvent('common:waste-center:form-modal:open')"
                            >
                                <i
                                    class="fa-plus"
                                    :class="$theme('contextmenu.icon')"
                                ></i>
                                <span>{{ $t('quick_actions.add_waste_center') }}</span>
                            </a>
                        </template>
                    </template>
                </div>
            </div>
        </template>
    </Submenu>
</template>
