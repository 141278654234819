import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type ProductRelationships = {
    productCategory: ToOneRelationship<ModelType.PRODUCT_CATEGORIES>;
    productUnit: ToOneRelationship<ModelType.PRODUCT_UNITS>;
    productImage: ToOneRelationship<ModelType.PRODUCT_IMAGES>;
    defaultVatRate: ToOneRelationship<ModelType.VAT_RATES>;
};

type ProductAttributes = {
    title: string;
    description: string;
    reference: string;
    defaultQuantity: number;
    price: number;
    purchasePrice: number;
    margin: number;
};

type ProductMeta = {
    count?: number;
};

export default class ProductSchema extends BaseSchema<ProductAttributes, ProductMeta, ProductRelationships> {
    protected type: ModelType = ModelType.PRODUCTS;
}
