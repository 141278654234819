import printJS from 'print-js';
import { useToasteo } from '@deegital/vue-trustup-io-toasteo';
import Contact from '~~/app/contact/models/Contact';
import Invoice from '~~/app/invoicing/models/Invoice';
import EndpointFactory from '~/app/factories/EndpointFactory';
import { ModelType } from '~/app/base/schemas/BaseSchema';
import InvoiceEndpoint from '~/app/invoicing/endpoints/InvoiceEndpoint';
import InvoiceSchema from '~/app/invoicing/schemas/InvoiceSchema';
import moment from '~/utils/moment';
import Pdf from '~/app/pdf/models/Pdf';

interface Fields {
    title: string;
    description: string;
    conditions: string;
    dueAt: string | null;
    contact: Contact | null;
}

export default function (invoice?: Invoice, onAction: (() => void) | null = null) {
    if (!invoice) {
        return {};
    }

    const toasteo = useToasteo();
    const { t } = useTranslation();
    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;

    const printPdf = async (payload: LoadingButtonClickedEventPayload | null = null) => {
        await pdfAction(payload, 'pdf', (pdf: Pdf) => {
            console.log('pdfAction', payload, pdf, 'printPdf');
            printJS({ printable: pdf.url, type: 'pdf' });
            toasteo.success({
                title: t('toasts.pdf_printed'),
                text: t('toasts.popup_helper'),
                // showUndo: false,
                // callback: () => {
                //     window.open(pdf.url, '_blank');
                // }
            });
        });
    };

    const downloadSignedPdf = async (payload: LoadingButtonClickedEventPayload | null = null) => {
        if (!invoice?.signedPdfUrl) {
            throw new Error('Invoice does not have a signed PDF URL');
        }

        window.open(invoice.signedPdfUrl, '_blank');
    };

    const downloadPdf = async (payload: LoadingButtonClickedEventPayload | null = null) => {
        await pdfAction(payload, 'pdf', (pdf: Pdf) => {
            console.log('downloadPdf', pdf.url);
            window.open(pdf.url, '_blank');
            toasteo.success({
                title: t('toasts.pdf_download'),
                text: t('toasts.popup_helper'),
                // showUndo: false,
                // callback: () => {
                //     window.open(response.pdf, '_blank');
                // }
            });
        });
    };

    const downloadPricelessPdf = async (payload: LoadingButtonClickedEventPayload | null = null) => {
        await pdfAction(payload, 'pricelessPdf', (pdf: Pdf) => {
            window.open(pdf.url, '_blank');
            toasteo.success({
                title: t('toasts.pdf_download'),
                text: t('toasts.popup_helper'),
                // showUndo: false,
                // callback: () => {
                //     window.open(response.pdf, '_blank');
                // }
            });
        });
    };

    const pdfAction = async (payload: LoadingButtonClickedEventPayload | null, action: string, callback: (pdf: Pdf) => void) => {
        if (!invoice || !invoice.getId()) {
            throw new Error('Invoice does not have an ID, cannot get its PDF yet.');
        }

        if (typeof endpoint[action] !== 'function') {
            throw new TypeError(`Endpoint does not have an action "${action}"`);
        }

        const response = await payloadOrPromise(payload, endpoint[action](invoice.getId()));
        console.log('pdfAction', response, action);
        if (response.data) {
            callback(response.data);
        }

        if (onAction) {
            onAction();
        }
    };

    const unarchive = async (payload: LoadingButtonClickedEventPayload | null = null) => {
        const schema = new InvoiceSchema({ attributes: { archivedAt: null }, id: invoice.getId() });
        await payloadOrPromise(payload, endpoint.update(schema));

        if (onAction) {
            onAction();
        }
    };

    const archive = async (payload: LoadingButtonClickedEventPayload | null = null) => {
        const schema = new InvoiceSchema({ attributes: { archivedAt: moment().toUtcDateTimeString() }, id: invoice.getId() });
        await payloadOrPromise(payload, endpoint.update(schema));

        if (onAction) {
            onAction();
        }
    };

    const unlinkWorksite = async (payload: LoadingButtonClickedEventPayload | null = null) => {
        const schema = new InvoiceSchema({ id: invoice.getId() });

        schema.addToOneRelationship('worksite', ModelType.WORKSITES, null);
        await payloadOrPromise(payload, endpoint.addInclude('worksite').update(schema));
        if (onAction) {
            onAction();
        }
    };

    const destroy = async (payload: LoadingButtonClickedEventPayload | null = null) => {
        await payloadOrPromise(payload, endpoint.destroy(invoice.getId()));
        useToasteoSuccess(t('toasts.element_deleted'));

        if (onAction) {
            onAction();
        }
    };

    const payloadOrPromise = async (payload: LoadingButtonClickedEventPayload | null, promise) => {
        return payload ? payload.loadUntil(promise) : promise;
    };

    return {
        downloadSignedPdf,
        downloadPdf,
        printPdf,
        downloadPricelessPdf,
        unarchive,
        archive,
        destroy,
        unlinkWorksite,
    };
}
