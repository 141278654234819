import { v4 as uuidv4 } from 'uuid';

export default function useContextMenuEvent(eventPrefix: string, onOpen: () => void, onClose: () => void) {
    const show = ref(false);
    const id = uuidv4();
    const options = ref({
        zIndex: 1000,
        minWidth: 250,
        x: 0,
        y: 0,
        scrollable: false,
        maxHeight: null,
    });

    const onUpdate = (v: boolean) => {
        if (!v) {
            hide();
        }
    };

    const hide = () => {
        show.value = false;
        onClose();
    };

    useListen(`${eventPrefix}:context-menu:open`, async (payload) => {
        console.log('open', payload);
        payload.event?.preventDefault();

        useEvent('context-menu:close');

        show.value = true;

        await nextTick();
        onOpen(payload);
        await nextTick();

        const element = document.getElementById(id);
        const elementHeight = element?.getBoundingClientRect().height || 250;
        const elementWidth = element?.getBoundingClientRect().width || 200;

        /**
         * Position the context menu at the mouse position.
         * By default we move it 10px up and left to position it just under the mouse cursor.
         *
         * If the menu overflows the window on the X or Y axis, move it up or left to make it fit.
         * We don't want to move it up if it does not fit either.
         */
        options.value.x = payload.event.x - 10;
        options.value.y = payload.event.y - 10;
        options.value.scrollable = false;
        options.value.maxHeight = null;

        if (payload.event.y + elementHeight > window.innerHeight && payload.event.y - elementHeight > 0) {
            options.value.y = payload.event.y - elementHeight;
        } else {
            // TODO: This is not working as expected. The menu is scrollable but it requires more fine tuning.
            if (payload.event.y + elementHeight > window.innerHeight) {
                // options.value.scrollable = true;
                // options.value.maxHeight = window.innerHeight - payload.event.y - 10;
            }
        }

        if (payload.event.x + elementWidth > window.innerWidth) {
            options.value.x = payload.event.x - elementWidth;
        }
    });

    useListen(`context-menu:close`, () => {
        hide();
    });

    useListen(`${eventPrefix}:context-menu:close`, () => {
        hide();
    });

    return {
        show,
        hide,
        options,
        onUpdate,
        id,
    };
}
