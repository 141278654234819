<template>
    <div
        ref="searchInput"
        class="flex items-center w-full space-x-2 relative group group-ellipsis"
    >
        <FormInput
            :input-name="`query-${props.product?.uuid}`"
            :label="`${$t('inventory.product.section.products.label', {
                number: item + 1,
            })}`"
            :placeholder="$t('form.labels.title')"
            :form="form"
            class="w-full transition-all cursor-move js-line-handle"
            container-class-name="w-full md:flex-auto relative"
            @focus="focused = true"
            @input="searchProducts()"
            @blur="reset()"
        >
            <!-- // TODO -->
            <template #after-input>
                <div
                    v-if="focused && (products?.lenght || !loading)"
                    :class="$theme('submenu.container.base', 'absolute top-full left-0 right-0')"
                >
                    <div
                        v-if="loading"
                        class="relative py-6 bg-white"
                    >
                        <Loader />
                    </div>

                    <div v-else>
                        <SubmenuEmptyContent v-if="!products.length" />
                        <div :class="$theme('submenu.items', 'px-2')">
                            <a
                                v-for="productItem in products"
                                :key="productItem.uuid"
                                href="#"
                                :class="$theme('submenu.item', 'text-ellipsis overflow-hidden')"
                                @click.prevent="selectProduct(productItem)"
                            >
                                <div>
                                    <span class="block text-[13px]">
                                        {{ productItem.title }}
                                    </span>
                                    <div class="flex items-center">
                                        <span class="block -mt-[3px] text-[11px] text-gray-600">
                                            {{ $price(productItem.price) }} ({{ productItem.default_quantity }} {{ productItem.product_unit }})
                                        </span>
                                        <span v-if="productItem.parent === 'composed'"><i class="fa-regular fa-list text-md text-gray-500 transition-all w-[15px] pl-2"></i></span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div
                            v-if="meiliSearchTotalPages > 1"
                            class="p-4 text-xs text-gray-400 whitespace-normal bg-neutral-50"
                        >
                            {{
                                $t('invoicing.invoice_form.products_more_results', {
                                    total: products?.length,
                                })
                            }}
                        </div>
                    </div>
                </div>
            </template>
        </FormInput>
        <i
            class="w-3 h-3 opacity-50 cursor-pointer fa-regular fa-xmark hover:opacity-100"
            @click="removeProduct()"
        ></i>
    </div>
</template>
<script lang="ts" setup>
    import { onClickOutside } from '@vueuse/core';
    import { ProductsIndexSchema } from '../../../app/common/schemas/ProductsIndexSchema';
    import { ProductSearchFields } from './FormModal.vue';
    import Form from '@/classes/Form';

    const props = defineProps({
  form: null,
  item: null,
  product: null,
  isProduct: { type: Boolean }
});

    const emit = defineEmits(["selectProduct", "removeProduct"]);

    const searchInput = ref<null | HTMLElement>(null);
    const focused = ref(false);
    const products = ref();
    const loading = ref(true);
    const { search } = useMeilisearch();
    const meiliSearchTotalPages = ref();
    onClickOutside(searchInput, () => (focused.value = false));

    const searchProducts = async () => {
        const results = await search({
            model: 'products',
            query: props.form.get(`query-${props.product?.uuid}`),
            hitsPerPage: 5,
        });
        meiliSearchTotalPages.value = results?.totalPages;
        products.value = results.hits;
        loading.value = false;
    };

    const reset = () => {
        props.form.set(`query-${props.product?.uuid}`, props.form.get('products')[props.item].title);
    };
    const selectProduct = (product: ProductsIndexSchema) => {
        focused.value = false;
        emit('selectProduct', product);
        props.form.set(`query-${product.uuid}`, product.title);
    };

    const removeProduct = () => {
        emit('removeProduct');
        props.form.set(`query-${props.product?.uuid}`, '');
    };

    const init = () => {
        if (props.product?.title) {
            props.form.set(`query-${props.product.uuid}`, props.product.title);
            return;
        }

        props.form.set(`query-${props.product?.uuid}`, '');
    };
    init();
</script>
