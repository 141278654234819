<script setup lang="ts">
    import ProductImage from '@/app/inventory/models/ProductImage';
    import ProductImageCollection from '@/app/inventory/collections/ProductImageCollection';
    import ProductImageSchema from '@/app/inventory/schemas/ProductImageSchema';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import EndpointFactory from '@/app/factories/EndpointFactory';
    import { ImageUploader } from '@deegital/js-trustup-io-uploads';
    import { makeId } from '~/utils/string-helpers';
    import { storeToRefs } from 'pinia';

    const { load } = useProductImageSelectStore();
    const { loading, collection } = storeToRefs(useProductImageSelectStore());
    const inputFileId = ref(makeId());
    const inputFile = ref<HTMLInputElement | null>(null);
    const endpoint = EndpointFactory.make(ModelType.PRODUCT_IMAGES);
    const imageUploader = new ImageUploader({
        dimensions: {
            width: 400,
            height: 400,
        },
    });
    const submenu = ref<SubmenuInterface | null>(null);
    const emit = defineEmits(['selected']);
    const uploading = ref<boolean>(false);
    const selected = ref<ProductImage | null>(null);

    const props = defineProps({
  selected: null,
  clickabled: { type: Boolean, default: true },
  placeholderIconSize: { default: 'text-2xl' }
});

    const isSelected = (productImage: ProductImage) => selected.value?.getId() == productImage.getId();
    const selectProductImage = (productImage: ProductImage) => {
        submenu.value?.toggle();
        const value = isSelected(productImage) ? null : productImage;
        selected.value = value;
        emit('selected', value);
    };

    const openSubmenu = () => {
        submenu.value?.show();
        if (loading.value) {
            load();
        }
    };

    const triggerInputFile = () => {
        inputFile.value?.click();
    };

    const onFileUpload = async (event: Event) => {
        event.preventDefault();
        uploading.value = true;
        const uploaderResponse = await imageUploader.get(event.target.files[0]);
        inputFile.value!.value = '';

        const schema = ProductImageSchema.make({
            attributes: {
                image: {
                    name: uploaderResponse.name,
                    source: uploaderResponse.source,
                },
            },
        });

        const response = await endpoint.upload(schema);
        if (response.data) {
            await wait(500);
            selectProductImage(response.data);
            useToasteoSuccess('toasts.upload_success');
        }
        uploading.value = false;
    };

    watch(
        () => props.selected,
        (value) => {
            if (value) {
                // console.log('Select value has changed via props', value, selected.value);
            }
        }
    );

    onMounted(() => {
        if (props.selected) {
            selected.value = props.selected;
        }
        // load();
    });
</script>

<template>
    <Submenu ref="submenu">
        <template v-slot:default>
            <div
                class="w-full transition-all rounded cursor-pointer aspect-square"
                :class="selected ? 'bg-cover bg-center' : 'flex items-center justify-center hover:bg-neutral-200 bg-neutral-100'"
                @click.prevent="clickabled ? openSubmenu() : ''"
                :style="`background-image: url(${selected ? selected.url : ''})`"
            >
                <i
                    class="text-gray-400 fa-regular fa-image"
                    :class="placeholderIconSize"
                    v-if="!selected"
                ></i>
            </div>
        </template>
        <template v-slot:submenu>
            <div class="relative w-[400px] py-2">
                <div
                    class="py-12"
                    v-if="loading"
                >
                    <Loader />
                </div>
                <div
                    v-else
                    class="grid grid-cols-4 gap-2"
                    :key="collection?.getInternalId()"
                >
                    <label
                        :for="inputFileId"
                        class="flex items-center justify-center w-full transition-all rounded cursor-pointer hover:bg-neutral-200 bg-neutral-100 aspect-square"
                    >
                        <Loader v-if="uploading" />
                        <i
                            class="text-2xl text-gray-400 fa-regular fa-upload"
                            v-else
                        ></i>
                        <input
                            :id="inputFileId"
                            ref="inputFile"
                            type="file"
                            class="hidden"
                            @change="onFileUpload"
                            accept="image/png, image/gif, image/jpeg"
                        />
                    </label>
                    <div
                        class="relative bg-center bg-cover cursor-pointer aspect-square"
                        :style="`background-image: url(${productImage.url})`"
                        v-for="productImage in collection?.get()"
                        :key="productImage.getInternalId()"
                        @click.prevent="selectProductImage(productImage)"
                    >
                        <div
                            v-if="isSelected(productImage)"
                            class="absolute top-0 right-0 flex items-center justify-center w-5 h-5 -mt-2 -mr-2 rounded-full bg-primary"
                        >
                            <i class="fa-solid fa-sharp fa-check text-[.6rem] text-white" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Submenu>
</template>
