import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type ProductSectionRelationships = {
    products: ToManyRelationship<ModelType.PRODUCTS>;
};

type ProductSectionAttributes = {
    title: string;
};

type ProductSectionMeta = {
    // count?: number;
};

export default class ProductSectionSchema extends BaseSchema<ProductSectionAttributes, ProductSectionMeta, ProductSectionRelationships> {
    protected type: ModelType = ModelType.PRODUCT_SECTIONS;
}
