import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type ProductImageRelationships = {};

type ProductImageAttributes = {
    name: string;
    url: string;
    mediaId: string;
};

type ProductImageMeta = {};

export default class ProductImageSchema extends BaseSchema<ProductImageAttributes, ProductImageMeta, ProductImageRelationships> {
    protected type: ModelType = ModelType.PRODUCT_IMAGES;
}
