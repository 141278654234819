import BaseModel from '~~/app/base/models/BaseModel';
import { onMounted } from 'vue';
import { useListen, useEvent } from './useEventBus';

export default function (modalName: string, onOpened: Function | null = null, onClosed: Function | null = null) {
    const modal = ref(null);

    useListen(`${modalName}:open`, (payload: any) => {
        useEvent('modal:open', modalName);
        if (onOpened) onOpened(payload);
    });
    useListen(`${modalName}:close`, () => {
        useEvent('modal:close', modalName);
    });

    useListen('modal:close', (name: string) => {
        if (name == modalName && onClosed) onClosed();
    });

    return {
        modalName,
        modal,
    };
}
