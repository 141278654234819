import { ModelType } from '@/app/base/schemas/BaseSchema';
import ProductImageCollection from '@/app/inventory/collections/ProductImageCollection';
import ProductImage from '@/app/inventory/models/ProductImage';

export const useProductImageSelectStore = defineStore('ProductImageSelectStore', () => {
    const { load, loading, collection } = useCollectionLoader<ProductImage, ProductImageCollection>(ModelType.PRODUCT_IMAGES, [], () => {
        return {
            page: {
                size: 39,
            },
        };
    });

    return {
        load,
        loading,
        collection,
    };
});
